.footer {
    padding-top: 40px;

    @include mq(md) {
        padding-top: 80px;
    }

    &__logo {
        display: block;
        width: 60%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;

        @include mq(md) {
            margin-bottom: 0;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__wrapper {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        @include mq(md) {
            margin-bottom: 0;
        }
    }

    &__list {
        width: 48%;

        @include mq(md) {
            width: 45%;
        }
    }

    &__item {
        margin-bottom: 20px;
        
        &:not(:last-child) {
            border-bottom: 1px solid $lightGray;
            padding-bottom: 20px;
        }

        a {
            color: $midGray;
            text-decoration: none;
            font-size: 14px;
            font-weight: 900;
            white-space: nowrap;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;        

        @include mq(md) {
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }
    }

    &__group {
        width: 100%;

        &:first-child {
            order: 2;

            @include mq(md) {
                order: 1;
            }
        }

        &:last-child {
            order: 1;

            @include mq(md) {
                order: 2;
            }
        }

        @include mq(md) {
            width: 50%;
        }
    }

    &__group-title {
        text-decoration: none;
        display: block;
        color: $midGray;
        font-size: 14px;
        margin: 0;
        margin-bottom: 20px;
        font-weight: 900;
        text-align: center;
    }

    &__social {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        li {
            margin: 0 10px;

            @include mq(md) {
                margin: 0 10px;
            }
        }

        a {
            color: $midGray;
            font-size: 20px;
        }
    }


    &__button {
        display: block;
        width: 60%;
        line-height: 40px;
        outline: none;
        text-decoration: none;
        text-align: center;
        border: 1px solid $turquoise;
        color: $turquoise;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700;
        border-radius: 7px;
        background: $white;
        margin-bottom: 40px;
        margin-left: auto;
        margin-right: auto;

        @include mq(md) {
            margin-bottom: 0;
            width: 100%;
        }

        &:hover {
            border: 1px solid ($turquoise + #333);
            color: $turquoise + #333;
        }
    }

    &__copyright {
        height: 80px;
        line-height: 80px;
        text-align: center;
        color: $midGray;
        font-size: 12px;
        border-top: 1px solid $lightGray;
        margin-bottom: 0;
        margin-top: 50px;
    }
}
