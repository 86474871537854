.faq {
    width: 100%;

    &__wrapper {
        background: $white;
        border-radius: 7px;
        margin-bottom: 20px;
        width: 100%;
    }

    &__question {
        padding: 20px 10px;
        display: flex;
        align-items: center;
        cursor: pointer;

        @include mq(md) {
            padding: 40px;
        }

        &.-open {
            .faq__plus-icon {
                .-vertical {
                    transform: translate3d(-50%, -50%, 0) rotate(90deg);
                }
            }
        }
    }

    &__plus-icon {
        min-width: 30px;
        height: 30px;
        border: 1px solid $blue;
        border-radius: 50%;
        position: relative;
        margin-right: 20px;

        @include mq(md) {
            margin-right: 40px;
            min-width: 50px;
            height: 50px;
        }

        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 15px;
            height: 1px;
            background: $blue;
            
            @include mq(md) {
                width: 25px;
            }

            &.-vertical {
                @include trans;

                width: 1px;
                height: 15px;

                @include mq(md) {
                    height: 25px;
                }
            }
        }
    }
    
    &__question-text {
        color: $darkGray;
        font-size: 18px;
        margin: 0;
        font-weight: 700;
        width: 100%;

        @include mq(md) {
            font-size: 24px;
        }
    }

    &__answer {
        @include trans;

        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        opacity: 0;
        height: 0;
        z-index: -1;
        pointer-events: none;
        padding-left: 10px;
        padding-right: 10px;

        @include mq(md) {
            padding-left: 130px;
            padding-right: 130px;
        }

        p {
            opacity: 0;
        }

        &.-open {
            opacity: 1;
            height: auto;
            z-index: 1;
            pointer-events: all;
            padding-bottom: 50px;
            
            p {
                opacity: 1;
            }

            .faq__answer__group,
            .faq__answer__title,
            .faq__answer__text,
            .faq__answer__list {
                opacity: 1;
            }
        }

        &__group {
            opacity: 0;
            width: 50%;
            margin-bottom: 20px;
            padding: 0 15px;
            
            @include mq(md) {
                padding: 0 80px 0 0;
                width: 33.33%;
            }

            img {
                display: block;
                width: auto;
                height: 50px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;

                @include mq(md) {
                    margin-left: 0;
                    margin-right: 0;
    
                }
            }

            p {
                line-height: 1.5;
            }
        }

        &__title {
            opacity: 0;
            text-transform: uppercase;
            color: $darkestGray;
            margin: 0;
            margin-bottom: 20px;
        }

        &__text {
            opacity: 0;
            margin: 0;
            margin-bottom: 20px;
        }

        &__list {
            opacity: 0;
            margin-bottom: 20px;
            list-style: square inside;

            li {
                margin-bottom: 10px;
            }
        }
    }
}