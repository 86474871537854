.article {
    width: 100%;
    
    &__image {
        width: 100%;
        height: 0;
        padding-bottom: 60%;
        overflow: hidden;
        position: relative;
        display: block;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: auto;
            height: 100%;
        }

    }

    &__content {
        background: $white;
        padding: 40px;
    }

    &__date {
        color: $midGray;
        font-size: 12px;
        margin-bottom: 10px;
        display: block;
    }

    &__title {
        color: $darkestGray;
        font-weight: 700;
        font-size: 24px;
        margin: 0;
        margin-bottom: 20px;
        display: block;
        text-decoration: none;
    }

    &__text {
        color: $darkGray;
        font-size: 14px;
        margin: 0;
        margin-bottom: 40px;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__show-article,
    &__share {
        border: none;
        background: none;
        color: $blue;
        text-transform: uppercase;
        font-size: 12px;
        text-decoration: none;
    }

    &__share {
        color: $midGray;
        text-transform: none;
        letter-spacing: 2px;

        &:hover {
            i {
                color: $blue;
            }
        }

        i {
            @include trans;

            margin-left: 10px;
            color: $midGray;
        }
    }
}