.nosotros {

    $n: &;
    
    &__hero {
        height: 35vh;
        position: relative;
        overflow: hidden;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: auto;
            height: 100%;

            @include mq(md) {
                width: 100%;
                height: auto;
            }
        }

        &__container {
            display: flex;
            align-items: center;
            height: 35vh;
        }

        &__title {
            color: $white;
            font-size: 40px;
            margin: 0;
            z-index: 2;
            position: relative;
        }
    }

    &__profile {

        &__image {
            width: 100%;
            padding-top: 40px;
            overflow: hidden;
            height: 50vh;
            margin-bottom: 40px;

            @include mq(md) {
                height: 60vh;
                margin-bottom: 0;
            }

            img {
                width: 100%;
                height: auto;

                @include mq(md) {
                    width: 90%;
                }
            }
        }

        &__content {
            display: flex;
            align-items: center;
            height: auto;
            margin-bottom: 40px;

            @include mq(md) {
                margin-bottom: 0;
                margin-top: 40px;
            }
        }

        &__subtitle {
            color: $turquoise;
            text-transform: uppercase;
            font-size: 16px;
            margin: 0;
            margin-bottom: 10px;
        }

        &__title {
            color: $turquoise;
            font-size: 30px;
            margin: 0;
            margin-bottom: 60px;
        }

        &__text {
            color: $darkestGray;
            font-weight: 900;
            font-size: 18px;
        }

        &__subtext {
            color: $darkestGray;
            font-size: 12px;
            margin: 0;
            margin-bottom: 20px;

            span {
                display: block;
                color: $darkGray;
            }
        }

        &__details {
            padding: 0 0 40px 0;
            position: relative;

            @include mq(md) {
              padding: 40px 0;
            }

            p {
                margin: 0;
                font-size: 18px;
                font-weight: 700;
                text-align: left;
            }

            &:after {
                content: '';
                position: absolute;
                background: $white;
                top: 0;
                left: -100%;
                width: 1000%;
                height: 100%;
                z-index: -1;
            }
        }

        &__social {
            display: flex;

            li {
                height: 30px;
                width: 30px;
                background: $midGray;
                border-radius: 50%;
                margin-right: 5px;
            }

            a {
                color: $white;
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        &__more-info {
            @include trans;

            background: $white;
            height: 0;
            overflow: hidden;
            opacity: 0;
            position: relative;

            &.-open {
                height: auto;
                padding: 50px 0;
                overflow: visible;
                opacity: 1;

                #{$n}__profile__more-info-text {
                    height: auto;
                }
            }
        }

        &__more-info-text {
            color: $darkGray;
            padding-right: 40px;
            height: 0;
            overflow: hidden;
            
            p {
                margin: 0;
                margin-bottom: 20px;
                line-height: 1.5;
            }

            .-quote {
                font-size: 18px;
                font-style: italic;
                font-weight: 900;
                color: $darkestGray;
            }
        }

        &__more-button {
            background: transparent;
            border: 1px solid $turquoise;
            color: $turquoise;
            line-height: 40px;
            text-align: center;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            display: block;
            outline: none;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 700;
            border-radius: 7px;
            // margin-top: 40px;
            margin-bottom: 40px;

            &:after {
                content: '';
                position: absolute;
                background: $white;
                top: 0;
                left: -100%;
                width: 1000%;
                height: 100%;
                z-index: -1;
            }


            @include mq(md) {
                width: 150px;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }

    &__equipo {
        padding: 100px 0;

        &__title {
            color: $darkestGray;
            font-size: 30px;
            margin: 0;
            margin-bottom: 80px;
        }

        &__container {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 40px;
        }

        &__image {
            width: 150px;
            height: 150px;
            overflow: hidden;
            margin-bottom: 20px;
            background: lighten($blue, 40%);
            border-radius: 50%;
            position: relative;

            @include mq(md) {
                margin-bottom: 0;
            }

            img {   
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate3d(-50%, -50%, 0);
                width: 150px;
                height: 150px;
                border-radius: 50%;
            }
        }

        &__content {
            width: 100%;
            padding-left: 15px;

            @include mq(md) {
                width: 50%;
            }
        }

        &__content-title {
            color: $turquoise;
            margin: 0;
            margin-bottom: 20px;
        }

        &__text {
            color: $darkestGray;
            font-size: 18px;
            margin: 0;
            margin-bottom: 5px;
        }

        &__subtext {
            color: $midGray;
            font-size: 12px;
            margin: 0;
            margin-bottom: 20px;

            span {
                display: block;
                color: $darkGray;
            }
        }

        &__social {
            display: flex;

            li {
                height: 30px;
                width: 30px;
                background: $midGray;
                border-radius: 50%;
                margin-right: 5px;
            }

            a {
                color: $white;
                height: 30px;
                width: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &__locations {
        padding: 50px 0;
        background: lighten($blue, 40%);

        @include mq(md) {
            padding: 100px 0;
        }
    }
}