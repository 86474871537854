.locations {
    
    $l: &;

    &__subtitle {
        color: $turquoise;
        text-transform: uppercase;
        font-size: 16px;
        text-align: center;
        margin: 0;
        margin-bottom: 10px;

        @include mq(md) {
            font-size: 20px;
        }
    }

    &__title {
        color: $darkestGray;
        font-size: 24px;
        letter-spacing: 1.3px;
        text-align: center;
        margin: 0;
        margin-bottom: 20px;

        @include mq(md) {
            font-size: 28px;
        }
    }

    &__container {
        border-radius: 7px;
        overflow: hidden;
        margin-bottom: 20px;

        @include mq(md) {
            margin-bottom: 0;
        }
    }

    &__image {
        width: 100%;
        height: 0;
        overflow: hidden;
        padding-bottom: 50%;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: 100%;
            height: auto;
        }
    }

    &__content {
        background: $white;
        padding: 20px;
        position: relative;
        min-height: 220px;
    }

    &__content-title {
        margin: 0;
        margin-bottom: 20px;
        color: $turquoise;
    }

    &__content-text {
        margin: 0;
        margin-bottom: 20px;
        color: $darkestGray;
        font-size: 16px;
    }

    &__buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &__map-link,
    &__appoinment {
        position: absolute;
        bottom: 20px;
        left: 20px;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 900;
        font-size: 14px;
        color: $turquoise;
        display: block;

        &:hover {
            color: $linkHover + #333;
        }
    }


    &__appoinment {
        left: auto;
        right: 20px;
        background: $turquoise;
        height: 20px;
        font-size: 12px;
        color: $white;
        line-height: 20px;
        padding: 0 10px;
        border-radius: 3px;
        bottom: 18px;
    }

    &--horizontal {
        #{$l}__heading {
            height: 100px;
        }


        #{$l}__subtitle,
        #{$l}__title {
            text-align: left;
        }

        #{$l}__title {
            margin-bottom: 40px;
        }

        #{$l}__container {
            display: block;
            margin-bottom: 40px;

            @include mq(md) {
                display: flex;
            }
        }

        #{$l}__image {
            padding-bottom: 50%;
            width: 100%;

            @include mq(md) {
                padding-bottom: 37%;
                width: 50%;
            }

            img {
                height: auto;
                width: 100%;

                @include mq(md) {
                    height: 100%;
                    width: auto;
                }
            }
        }

        #{$l}__content {
            min-height: 0;
            width: 100%;

            @include mq(md) {
                width: 50%;
            }
        }

        #{$l}__content-text {
            margin-bottom: 40px;
        }
    }
}
