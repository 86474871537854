.header {

    $h: &;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    background: $white;
    
    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 10vh;
        padding: 15px 0;

        @include mq(md) {
            padding: 10px 0;
            height: 12vh;
        }
    }

    &__logo {
        height: 100%;
        z-index: 1000;

        img {
            height: 100%;
            width: auto;
        }
    }

    &__wrapper {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        @include mq(md) {
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        transform: translateX(-100%);
        background: $white;
        display: flex;
        justify-content: center;
        align-items: center;

        @include mq(md) {
            transform: translateX(0);
            position: static;
            height: 100%;
        }
    }

    &__list {
        @include mq(md) {
            display: flex;
        }
    }

    &__item {
        margin: 20px 0;

        @include mq(md) {
            margin: 0 0 0 15px;
        }
        
        a {
            color: $midGray;
            text-decoration: none;
            text-transform: uppercase;
            font-weight: 900;
            font-size: 18px;
            white-space: nowrap;
            position: relative;

            @include mq(md) {
                font-size: 12px;
            }

            &:after {
                content: '';
                @include trans;
                transition-delay: .5s;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                width: 0;
                bottom: -5px;
                height: 2px;
                background: transparent;
            }

            &.-active {
                color: $linkHover;

                &:after {
                    content: '';
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 100%;
                    bottom: -5px;
                    height: 2px;
                    background: $turquoise;
                }
            }
            &:hover {
                color: $turquoise;
            }
        }
    }

    &__phone {
        border: 1px solid $turquoise;
        margin-left: 20px;
        height: 100%;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 10px;
        text-decoration: none;
        z-index: 1000;
        font-weight: 900;

        @include mq(md) {
            padding: 0 20px;
            height: 40px;
        }

        i {
            margin-right: 10px;
            color: $turquoise;
        }

        span {
            font-size: 12px;
            color: $turquoise;

            @include mq(md) {
                font-size: 16px;
            }
        }

        &:hover {
            border: 1px solid ($turquoise + #333);

            i,
            span {
                color: $turquoise + #333;
            }
        }
    }

    &__hamburguer {
        cursor: pointer;
        width: 25px;
        height: 21px;
        margin-left: 20px;
        
        @include mq(md) {
            display: none;
        }

        span {
            background-color: $mainColor;
            border-radius: 2px;
            content: '';
            display: block;
            width: 100%;
            height: 2px;

            &:nth-child(1) {
                animation:outT 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(2) {
                margin: 7px 0;
                animation:outM 0.6s $easing backwards;
                animation-direction:reverse;
            }
            &:nth-child(3) {
                animation:outBtm 0.6s $easing backwards;
                animation-direction:reverse;
            }
        }
    }

    &.-open {
        #{$h}__hamburguer {
            span:nth-child(1) {
                animation:inT 0.6s $easing forwards;
            }
            span:nth-child(2) {
                animation:inM 0.6s $easing forwards;
            }
            span:nth-child(3) {
                animation:inBtm 0.6s $easing forwards;
            }
        }

        #{$h}__nav {
            transform: translateX(0);
        }
    }
}

//hamburguer animations
@keyframes inM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes outM{
50%{transform:rotate(0deg);}
100%{transform:rotate(45deg);}
}

@keyframes inT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes outT{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(9px) rotate(0deg);}
100%{transform: translateY(9px) rotate(135deg);}
}

@keyframes inBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
@keyframes outBtm{
0%{transform: translateY(0px) rotate(0deg);}
50%{transform: translateY(-9px) rotate(0deg);}
100%{transform: translateY(-9px) rotate(135deg);}
}
