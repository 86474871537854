/*!
 * Bootstrap v3.3.7 (http://getbootstrap.com)
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "node_modules/bootstrap/scss/functions";
@import "../mixins/bootstrap-variables";
//@import "node_modules/bootstrap/scss/variables";

@import "node_modules/bootstrap/scss/mixins/breakpoints";
@import "node_modules/bootstrap/scss/mixins/grid-framework";
@import "node_modules/bootstrap/scss/mixins/grid";

@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/utilities/display";
@import "node_modules/bootstrap/scss/utilities/flex";
@import "node_modules/bootstrap/scss/utilities/spacing";
