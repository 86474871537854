.contacto {
    background: lighten($blue, 40%);

    &__hero {
        height: 35vh;
        position: relative;
        overflow: hidden;
        margin-bottom: 50px;

        @include mq(md) {
            margin-bottom: 0;
        }

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            width: auto;
            height: 100%;

            @include mq(md) {
                width: 100%;
                height: auto;
            }
        }

        &__container {
            display: flex;
            align-items: center;
            height: 35vh;
        }

        &__title {
            color: $white;
            font-size: 40px;
            margin: 0;
            z-index: 2;
            position: relative;
        }
    }

    &__locations {
        padding: 50px 0;
    }

    &__container{ 
        padding: 0 0 50px 0;

        @include mq(md) {
            padding: 50px 0;
        }

        &__heading{ 
            height: 100px;
        }
    }

    &__title {
        margin: 0;
        color: $turquoise;
        font-size: 20px;

        @include mq(md) {
            margin-left: 50px;
        }
    }

    &__form {
        background: $white;
        padding: 20px 40px;
        border-radius: 7px;
        overflow: hidden;
        
        @include mq(md) {
            margin-left: 50px;
            padding: 40px 60px;
        }
    }

    &__form-group {
        width: 100%;
        margin-bottom: 20px;
        height: 50px;

        &--textarea {
            height: 150px;
            margin-bottom: 40px;
        }
    }

    &__input {
        border: none;
        border-bottom: 1px solid $midGray;
        padding: 10px 0;
        color: $midGray;
        height: 100%;
        width: 100%;
        background: none;
        resize: none;
        font-size: 14px;
        outline: none;
    }

    &__button {
        background: $blue;
        color: $white;
        height: 50px;
        text-transform: uppercase;
        border: 1px solid $blue;
        border-radius: 7px;
        box-shadow: 0 5px 20px 1px lighten($blue, .15);
        width: 100%;
        margin-bottom: 20px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
    }
}